.body {
  background-color: #dbddeb;
}

.theme {
  background-color: purple;
  color: white;
}

.theme a {
  color: white;
}

.form-group.required .control-label:after {
  content:"*";
  color:red;
}

.content-body {
  min-height: 100vh;
  background-color: white;
}

